<template>
	<Metier name="environnement" title="Environnement" :main-menu="[{title: 'Ingénieur environnement', missions: ['Propose et met en œuvre la politique d\'hygiène industrielle du site', 'Coordonne et réalise des audits, contrôles et observations des tâches sur le terrain', 'Appuie les équipes d\'intervention en cas d\'accident avec risque environnemental', 'Rassemble et gère les données nécessaires à l\'analyse des risques professionnels'], competences: ['Formation en hygiène industrielle avec expérience significative', 'Connaissance du raffinage et du milieu pétrolier', 'Connaissance des risques industriels', 'Expérience des problématiques environnementales'], qualites: ['Leadership', 'Rigueur', 'Sens du contact', 'Organisation']}, {title: 'Technicien environnement', missions: ['Assure le suivi de la qualité de l\'air et de l\'eau (mesure des émissions de la plateforme, analyse des incidents, propositions de modifications…)', 'S\'assure de la bonne gestion des déchets par les prestataires employés', 'Participe à l\'élaboration du rapport environnemental mensuel de la plateforme'], competences: ['Formation en chimie, environnement ou procédés', 'Connaissance du raffinage et du milieu pétrolier', 'Connaissances de problématiques environnementales', 'Expérience de gestion de contrat'], qualites: ['Rigueur', 'Sens relationnel', 'Esprit d\'analyse', 'Esprit de synthèse']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
